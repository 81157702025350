.card {
    width: 17rem;
    box-shadow: 1px 1px 7px 2px rgb(208, 208, 208);
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    background-color: white;
}

.icon {
    margin: auto;
    display: block;
    width: 3rem;
    height: 3rem;
}

.head {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.txt {
    text-align: center;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .card {
        width: 23rem;

        border-radius: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        background-color: white;
    }

    .icon {

        width: 5rem;
        height: 5rem;
    }

    .head {
        text-align: center;
        font-size: 2.3rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .txt {
        text-align: center;
        font-size: 1.8rem;
        font-family: 'Poppins', sans-serif;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
    }

}