.contain {
    background-color: rgb(209, 239, 252);
    padding-top: 2rem;
    padding-bottom: 4rem;
    margin-bottom: 5rem;
    margin-top: 4rem;
}

.hold {

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.head {
    text-align: center;
    font-family: 'Fredoka', sans-serif;
    font-size: 2.7rem;
    font-weight: 600;
    margin-top: -0.05rem;
    margin-bottom: 1rem;
}

.head2hold {
    display: flex;
    justify-content: center;
}

.head2 {}

.line {
    margin-bottom: 4rem;
}

.cardHead {

    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.3rem;
}

.card {
    width: 18rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgb(244, 245, 255));
    box-shadow: 1px 1px 5px 1px rgb(211, 211, 211);
    border: 1px solid gray;
    color: black;
    text-align: center;
    border-radius: 0.4rem;
}

.cardtxt {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    color: rgb(63, 63, 63);
}

.icon {
    margin-right: 0.2rem;
    margin-top: -1rem;
    width: 3rem;
    height: 3rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .contain {

        background-color: rgb(225, 236, 255);
        padding-top: 2rem;
        padding-bottom: 4rem;
        margin-bottom: 5rem;
        margin-top: 4rem;
        width: 70.4rem;
    }

    .hold {

        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6rem;
    }

    .card {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgb(244, 245, 255));
        box-shadow: 1px 1px 5px 1px rgb(211, 211, 211);
        border: 1px solid gray;
        color: black;
        text-align: center;
        width: 30rem;
        border-radius: 0.4rem;
    }

    .cardHead {
        font-size: 2rem;
    }


    .cardtxt {
        font-size: 1.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

}