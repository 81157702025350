.hold {
    background-image:
        url("./personal-loan.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 41rem;
    background-attachment: fixed;

}

/*.head {
    color: white;
    font-size: 6rem;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
    text-align: center;

}*/

.headhold {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.head1 {
    margin-left: 5rem;

}

.slidehold {
    margin-top: -2rem;
}

.head {
    font-size: 6rem;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
}

.s {
    color: rgb(1, 3, 158);
}

.aa {
    color: rgb(53, 174, 255);
}

/*//////////////////

.head {

    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 6rem;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;

}*/

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}


@media screen and (max-width:850px) {
    html {
        font-size: 45%;
    }

    .hold {
        margin-top: 7rem;
        width: 70.6rem;
        height: 51rem;

    }

    .head {
        font-size: 5.5rem;
    }
}