 @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


 .hold {
     margin-top: -4.5rem;
     font-family: 'Poppins', sans-serif;
 }

 .head {
     font-family: 'Fredoka', sans-serif;
     font-size: 2.5rem;
     font-weight: 600;
     text-align: center;
     margin-bottom: 0.1rem;
 }

 .mainhold {
     background-image: url("./asset/aboutmain.jpg");
     width: 100%;
     height: 27rem;
 }

 .visionHold {
     margin-top: 3rem;
     padding-top: 1rem;
     padding-bottom: 1rem;
     background-color: rgb(238, 244, 255);
     color: black;
 }

 .mainhead {
     text-align: center;
     font-size: 5rem;
     font-weight: 700;
     max-width: max-content;
     background: -webkit-linear-gradient(0deg, #000000 0%, #48abe0 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
 }

 .mainhead2 {
     margin-top: -5rem;
     text-align: center;
     font-size: 3rem;
     font-weight: 600;
     max-width: max-content;
     background: -webkit-linear-gradient(0deg, #bff0f5 0%, #1874a6 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
 }

 .txtHead {
     text-align: center;
     font-family: 'Poppins', sans-serif;
     font-weight: bold;
     font-size: 1.6;
 }

 .txt {
     margin-left: 7rem;
     margin-right: 7rem;
     text-align: center;
     font-family: 'Poppins', sans-serif;

 }

 .sp {
     text-align: center;
     font-weight: bold;
     color: #1874a6;
 }

 @media screen and (max-width:768px) {
     .html {
         font-size: 45%;

     }

     .mainhold {
         margin-top: 2rem;
         background-image: url("./asset/aboutmain.jpg");
         width: 70.6;
         height: 27rem;
     }

     .mainhead {
         text-align: center;
         font-size: 6rem;
         font-weight: 700;

     }

     .mainhead2 {
         margin-top: -5rem;
         text-align: center;
         font-size: 4rem;
         font-weight: 600;

     }

     .sp {
         text-align: center;
         font-weight: bold;
         font-size: 2rem;
         color: #1874a6;
     }

     .txt {
         font-size: 1.8rem;
         margin-left: 7rem;
         margin-right: 7rem;
         text-align: center;


     }



 }