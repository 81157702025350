@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');


.card {
    color: black;
    background-color: transparent;
    width: 100%;
}

.head {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.txt {
    margin-top: -3rem;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;

}