.card {
    width: 15rem;
    border: 1px solid rgb(225, 225, 225);
    padding-top: 1rem;
    border-radius: 0.2rem;
}

.card:hover {

    border: 1px solid rgb(225, 225, 225);
    padding-top: 1rem;
    border-radius: 0.2rem;
    background-image: linear-gradient(180deg, rgb(205, 238, 250), #ffffc6);
}

.image {
    margin: auto;
    display: block;
    width: 4rem;
    height: 4rem;
}

.txt {
    font-size: 1.1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .card {

        width: 23rem;
        padding-top: 1rem;
        border-radius: 0.2rem;
    }

    .image {
        margin: auto;
        display: block;
        width: 9rem;
        height: 9rem;
    }


    .txt {
        font-size: 1.8rem;
    }
}