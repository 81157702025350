.titlehold {
    margin-top: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
}

.title {
    text-align: center;
    color: white;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.divsHold {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.left {
    width: 26rem;
    margin-left: 1rem;
    margin-top: 2rem;
}

.middle {
    width: 26rem;
}

.right {
    width: 26rem;
    margin-right: 1rem;
}

.leftIn {
    font-size: 2.1rem;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -2.2rem;
    text-align: center;
}



.homehold {
    margin-top: 7rem;
}

.home {
    margin: auto;
    display: block;
    width: 15rem;
}

.card {
    margin-top: 4rem;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
}

.cardTitle {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: rgb(36, 111, 240);
    font-family: 'Poppins', sans-serif;
}

.cardtxt {
    font-family: 'Poppins', sans-serif;
    margin-left: 2rem;
    margin-right: 2rem;
}

.card1 {
    margin-top: 4rem;
    background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
}

.cardTitle1 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.cardtxt1 {
    font-family: 'Poppins', sans-serif;
    margin-left: 2rem;
    margin-right: 2rem;
    color: white;
}

.eligibilityHold {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 1px 2px 5px 3px rgb(204, 204, 204);
    margin-left: 3rem;
    margin-right: 3rem;
    border-radius: 2rem;
    color: black;
}

.docHold {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;


}

.eligiHead1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
}

.eligiHead2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    color: rgb(151, 151, 151);
    margin-left: 1rem;
    margin-right: 1rem;
}

.dochead {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
}

.list {
    color: gray;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .Hold {
        width: 70.6rem;
        margin-top: 11rem;
        background-color: rgb(244, 244, 244);

    }
}