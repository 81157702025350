.titlehold {
    margin-top: 3rem;
    padding-top: 0.6rem;
    padding-bottom: 5rem;
    background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
}

.title {
    text-align: center;
    color: white;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.cardHold {
    margin-top: -6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 7rem;
}

.bottomHold {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 11rem;
}

.in {
    width: 14rem;
    height: 2.3rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    border: none;
    border-bottom: 2px solid navy;

}

.in2 {
    width: 31rem;
    height: 9rem;
    margin-left: 2rem;
    margin-bottom: 2rem;

}

.getHead {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: navy;
}

.getTxt {
    margin-top: -1rem;
    width: 21rem;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}

.btn {
    margin: auto;
    display: block;
    width: 11rem;
    height: 2.7rem;
    border: none;
    background-color: navy;
    color: white;
    font-size: 1.3rem;
    border-radius: 0.5rem;
}



@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .titlehold {
        margin-top: 3rem;
        padding-top: 4rem;
        padding-bottom: 5rem;
        background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
    }

    .hold {
        margin-top: 11rem;
        width: 70.6rem;
    }

    .cardHold {
        margin-top: -5rem;
        margin-bottom: 14rem;

        gap: 3rem;
    }

    .getTxt {

        width: 24rem;
        font-size: 1.8rem;
        font-family: 'Poppins', sans-serif;
    }

    .getHold {
        margin-top: -2rem;
        margin-bottom: 7rem;
    }

}