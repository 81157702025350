.hold {
    background-color: rgb(237, 249, 255);
    font-family: 'Poppins', sans-serif;
    padding-bottom: 7rem;
}

.titlehold {
    margin-top: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
}

.title {
    text-align: center;
    color: white;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.headtitle {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.rightIn1 {
    display: flex;
    justify-content: center;
}

.resulthold {
    margin-top: 3rem;

    display: flex;
    justify-content: center;

}

.in {
    margin-bottom: 1.4rem;
    margin-top: -0.5rem;
    width: 14rem;
    height: 2rem;
    border-radius: 0.3rem;
}

.loanbtn {
    margin-top: 1rem;
    margin: auto;
    display: block;
    width: 14rem;
    height: 2.5rem;
    border-radius: 0.3rem;
    background-color: black;
    color: white;
    border: none;
    font-size: 1.3rem;
}

.loanbtn:hover {
    background-image: linear-gradient(to right, rgb(9, 1, 117), rgb(34, 178, 240));
}

.lab {
    color: gray;
}

.res {
    background-color: transparent;
    border: none;
    font-size: 1.3rem;
    color: red;
}