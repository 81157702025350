 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


 .hold {
     background-color: rgb(244, 244, 244);

 }

 .titlehold {
     margin-top: 2rem;
     padding-top: 0.6rem;
     padding-bottom: 0.6rem;
     background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
 }

 .title {
     text-align: center;
     color: white;
     font-size: 3rem;
     font-weight: 800;
     font-family: 'Poppins', sans-serif;
 }

 .divsHold {
     margin-top: 5rem;
     display: flex;
     flex-direction: row;
     justify-content: center;
     gap: 1rem;
 }

 .leftIn {
     font-size: 2.1rem;
     font-weight: bolder;
     font-family: 'Poppins', sans-serif;
     margin-bottom: -2.2rem;
 }

 .homehold {
     margin-top: 8rem;
 }

 .home {
     margin: auto;
     display: block;
     width: 15rem;
 }

 .left {
     width: 26rem;
     margin-left: 1rem;
     margin-top: 2rem;
 }

 .middle {
     width: 26rem;
 }

 .right {
     width: 26rem;
     margin-right: 1rem;
 }

 .card {
     margin-top: 4rem;
     background-color: white;
     padding-top: 1rem;
     padding-bottom: 1rem;
     border-radius: 1rem;
 }

 .card1 {
     margin-top: 4rem;
     background-image: linear-gradient(to right, rgba(2, 80, 132, 0.8), rgb(0, 20, 198));
     padding-top: 1rem;
     padding-bottom: 1rem;
     border-radius: 1rem;
 }


 .cardTitle {
     text-align: center;
     font-size: 1.1rem;
     font-weight: bold;
     color: rgb(36, 111, 240);
     font-family: 'Poppins', sans-serif;
 }

 .cardTitle1 {
     text-align: center;
     font-size: 1.1rem;
     font-weight: bold;
     color: white;
     font-family: 'Poppins', sans-serif;
 }



 .cardtxt {
     font-family: 'Poppins', sans-serif;
     margin-left: 2rem;
     margin-right: 2rem;
 }

 .cardtxt1 {
     font-family: 'Poppins', sans-serif;
     margin-left: 2rem;
     margin-right: 2rem;
     color: white;
 }

 .whyHead {
     text-align: center;
     font-family: 'Fredoka', sans-serif;
     font-size: 2.5rem;
     font-weight: 600;
 }

 .whyIcon {
     margin: auto;
     display: block;
     width: 2rem;
 }

 .whyHold {
     margin-top: 5rem;
 }

 .whyCardHold {
     display: flex;
     flex-direction: row;
     justify-content: center;
     flex-wrap: wrap;
 }

 .whyCard {
     padding-top: 1rem;
     padding-bottom: 1rem;
     width: 18rem;
     border: 1px solid rgb(227, 227, 227);
 }

 .whyTxt {
     text-align: center;
     font-family: 'Poppins', sans-serif;
     color: gray;
     margin-left: 0.5rem;
     margin-right: 0.5rem;
 }

 @media screen and (max-width:768px) {
     .html {
         font-size: 45%;

     }

     .hold {
         width: 70.6rem;
         margin-top: 11rem;
         background-color: rgb(244, 244, 244);

     }
 }