@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');



.head {
    text-align: center;
    font-family: 'Fredoka', sans-serif;
    font-size: 2.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 10rem;

}

.number {
    font-family: 'Quicksand', sans-serif;
    color: rgb(218, 1, 1);
    font-size: 4.8rem;
}

.numTxt {
    font-family: 'Quicksand', sans-serif;
    margin-left: -1rem;
    margin-top: -4.5rem;
    text-align: center;
}

@media screen and (max-width:768px) {
    .hold {
        margin-left: 12rem;
    }


    .top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 6rem;

    }
}