.hold {
    background-color: rgb(236, 246, 255);
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 5rem;
    flex-direction: row;
    gap: 6rem;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}



.ceo {
    width: 29rem;
    height: 43rem;
}


.right {
    width: 38rem;
}

.abt {
    color: rgb(2, 94, 193);
    font-weight: bold;
    font-size: 1.2rem;

}

.abt2 {
    margin-top: -1rem;
    font-size: 4rem;
    font-weight: 300;
}

.signhold {
    display: flex;
    flex-direction: row;
}

.abt3 {
    margin-top: -3rem;
}

.abt4 {
    font-size: 1.3rem;
    font-weight: bold;
}

.abt5 {
    margin-top: -1rem;
}

.sign {
    margin-top: -3rem;
    margin-left: 5rem;
}

.founder {
    font-family: 'Fredoka', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: -0.1rem;
}

.found {
    margin-top: -0.8rem;
}

@media screen and (max-width:768px) {
    .hold {
        width: 70.6rem;
    }

    .abt {
        margin-left: 1rem;
        text-align: center;

    }

    .abt2 {
        margin-top: -1rem;
        font-size: 4rem;
        font-weight: 300;
        margin-left: 3rem;
    }

    .sign {
        margin-top: 1rem;
        margin-left: 4rem;
        width: 9rem;
        height: 7rem;
    }

    .abt3 {
        margin-top: -3rem;
        font-size: 1.5rem;
        margin-left: 3rem;
    }

    .abt33 {

        font-size: 1.5rem;
        margin-left: 3rem;
    }


    .abt4 {
        font-size: 2.3rem;
        font-weight: bold;
        margin-left: 3rem;

    }

    .abt5 {
        margin-top: -1rem;
        font-size: 2rem;
        margin-left: 3rem;
    }

    .abt6 {
        margin-left: 3rem;
        font-size: 2rem;
    }

    .found {
        margin-left: 3rem;
        font-size: 2rem;
    }
}