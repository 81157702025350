.hold {
    margin-top: 4rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("./asset/5-factors.jpg");
    background-size: cover;
    height: 31rem;
}

.mainhead {
    margin-top: 7rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: 'Fredoka', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
    margin-bottom: 6rem;
}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        margin-top: 2rem;
        margin-bottom: 20rem;
        padding-top: 4rem;
        padding-bottom: 12rem;

        height: 126rem;
    }


    .mainhead {
        margin-top: 9rem;
        margin-bottom: 5rem;

        text-align: center;
        font-family: 'Fredoka', sans-serif;
        font-size: 3rem;
        font-weight: 700;
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6rem;
        margin-bottom: 6rem;
    }

    .bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 6rem;
    }


}