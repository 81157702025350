.container {
    margin-top: 5rem;
    background-color: black;
    padding-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
}

.hold {
    background-color: rgb(0, 0, 0);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.logo {
    width: 14rem;
    height: 7rem;
    margin-left: -1rem;
}

.logo2 {
    width: 14rem;
    height: 11rem;
}

.copy {
    color: white;
    width: 9rem;
}

.li {
    color: white;
    list-style: none;
    margin-bottom: 0.7rem;
}

.li:hover {
    color: rgb(1, 156, 173);
    cursor: pointer;
}

.hold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 9rem;
}

.extraHead {
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
}

.extra {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.insta {
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1rem;
}

.reach {
    margin-top: 1rem;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.locHold {
    display: flex;
    flex-direction: row;
}

.locTxt {

    color: white;
    width: 24rem;
}

.line {
    color: red;
}

.company {
    color: white;
    text-align: center;
    font-size: 0.9rem;
}

.hrt {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .container {

        width: 71rem;
        margin-top: 5rem;
        background-color: black;
        padding-bottom: 1rem;
        font-family: 'Poppins', sans-serif;
    }

    .hold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 11rem;
    }

    .logo {
        margin: auto;
        display: block;
        width: 41rem;
        height: 16rem;

    }

    .socialHold {
        margin-left: 5rem;
        display: flex;
        justify-content: center;
    }

    .copy {
        margin-top: 7rem;
        margin-left: -18rem;
        font-size: 2rem;
        color: white;
        width: 21rem;
    }

    .insta {
        width: 5rem;
        height: 5rem;
        margin-right: 1.5rem;

    }

    .linksHold {
        margin: auto;
        display: block;
    }

    .li {
        font-size: 2rem;
        margin-right: 5rem;
    }

    .reachHold {
        margin: auto;
        display: block;
    }

    .locTxt {
        font-size: 2rem;
        width: 22rem;
    }

    .reach {
        margin-top: 1rem;
        width: 4rem;
        height: 4rem;
        margin-right: 2rem;
    }

    .hrt {
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 0.8rem;
        margin-right: 0.7rem;
        margin-top: 0.3rem;

    }

    .company {
        margin-top: 6rem;
        color: white;
        text-align: center;
        font-size: 1.8rem;
    }






}