.hold {
    margin-top: 9rem;
}

.head {
    text-align: center;
    font-size: 2.6rem;
    font-weight: 600;
    font-family: 'Fredoka', sans-serif;
    margin-bottom: 1rem;
}

.bankHold {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

.icon {
    width: 8rem;
    height: 5rem;
}

.nbfcHead {
    margin-top: 4rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Fredoka', sans-serif;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .icon {
        width: 12rem;
        height: 9rem;
    }

    .hold {
        margin-left: 9rem;
    }
}