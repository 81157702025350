@import url('https://fonts.googleapis.com/css2?family=Fredoka&display=swap');


.hold {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    height: 33rem;
    background-image: linear-gradient(rgba(2, 80, 132, 0.8),
            rgba(0, 14, 23, 0.8)),
        url("./asset/Shanghai.jpg")
}

.headhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.head {
    text-align: center;
    color: white;
    font-family: 'Fredoka', sans-serif;
    font-size: 3rem;
    font-weight: 600;
}

.sel {
    font-family: 'Fredoka', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    margin-top: 2.9rem;
    margin-left: 1rem;
    height: 4rem;
    background-color: transparent;
    color: chartreuse;
    border: none;
}

.loanIconHold {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    gap: 11.3rem;
}

.icon {
    width: 5rem;
}

.loanIconHold2 {
    font-family: 'Fredoka', sans-serif;
    font-size: 1.2rem;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8.5rem;
    margin-left: 2rem;
}

.InsuranceIconHold {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    gap: 5.6rem;
}

.InsuranceIconHold2 {
    font-family: 'Fredoka', sans-serif;
    font-size: 1.2rem;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5.3rem;
}

.btnHold {
    margin-top: 5rem;
}

.btn {
    width: 14rem;
    height: 4rem;
    border-radius: 0.3rem;
    margin: auto;
    display: block;
    font-size: 1.2rem;

}

.btn:hover {
    background-image: linear-gradient(to right, rgb(0, 187, 255), rgb(238, 252, 255));
    color: white;
    display: block;
    font-size: 1.2rem;

}

.linker {
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        padding-top: 4rem;
        width: 70.6rem;
        height: 43rem;

    }

    .loanIconHold {
        font-size: 2rem;
        gap: 10.5rem;
    }

    .loanIconHold2 {
        font-size: 1.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .InsuranceIconHold2 {

        font-size: 1.2rem;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 4rem;
        margin-left: 1rem;
    }

    .btn {
        width: 19rem;
        height: 6rem;
        border-radius: 0.5rem;
        margin: auto;
        display: block;
        font-size: 2rem;
        border: none;

    }



}