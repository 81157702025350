.head {
    text-align: center;
    font-size: 2.6rem;
    font-weight: 600;
    font-family: 'Fredoka', sans-serif;
}

.head2 {
    margin-top: -2rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: gray;
}

.cardhold {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        margin-top: 8rem;
        margin-left: 5rem;
    }
}