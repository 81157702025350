.whyHead {
    text-align: center;
    font-family: 'Fredoka', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.9rem;
}

.whyIcon {
    margin: auto;
    display: block;
    width: 2rem;
}

.whyHold {
    margin-top: 5rem;
}

.whyCardHold {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.whyCard {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 18rem;
    border: 1px solid rgb(227, 227, 227);
}

.whyTxt {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: gray;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.newHead {
    text-align: center;
    font-size: 2.5rem;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    margin-bottom: -0.03rem;
}

.newHold2 {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    background-color: rgb(228, 249, 255);
    padding-top: 3rem;
    padding-bottom: 3rem;

}

.newRight {}

.newLeft {
    width: 50%;
    font-family: 'Poppins', sans-serif;
}

.newLogo {
    width: 29rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        width: 70.6rem;
        margin-top: 12rem;
        margin-bottom: 17rem;
    }

    .whyHold {
        margin-top: 5rem;
    }

    .whyCard {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 29rem;
        border: 1px solid rgb(227, 227, 227);
    }

    .whyIcon {
        margin: auto;
        display: block;
        width: 9rem;
    }

    .whyTxt {
        text-align: center;
        font-size: 2rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .whyHead {
        text-align: center;
        font-family: 'Fredoka', sans-serif;
        font-size: 3.5rem;
        font-weight: 600;
    }


}