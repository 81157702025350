.hold {


    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background-color: black;
    font-size: 0.9rem;

    color: white;

}

.icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
}

.callbtnHold {
    margin-left: 1rem;
}