.hold {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
}

.leftIn {
    filter: grayscale(100%);
    width: 100%;
    height: 30rem;
    background-size: cover;
}


.righthead {
    font-size: 2.7rem;
    font-weight: 600;
    font-family: 'Fredoka', sans-serif;
}

.righthead2 {
    margin-top: -3rem;
    margin-bottom: 0.5rem;
    font-size: 2.7rem;
    font-weight: 600;
    /*font-family: 'Poppins', sans-serif;*/
    font-family: 'Fredoka', sans-serif;
}

.icon {
    margin-top: 0.9rem;
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
}

.rIn {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .hold {
        margin-left: 4rem;
        margin-top: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5rem;
    }

    .icon {
        margin-top: 0.9rem;
        margin-right: 1rem;
        width: 4rem;
        height: 4rem;
    }


    .rIn {
        display: flex;
        flex-direction: row;
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 1rem;

    }
}