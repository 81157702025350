.card {
    background-color: black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    width: 18rem;
    border-radius: 0.9rem;
    box-shadow: 1px 1px 6px 1px rgb(0, 54, 201);
}

.star {
    margin: auto;
    display: block;
    width: 3rem;
    height: 3rem;
}

.head {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.txt {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;

    }

    .card {

        padding-top: 2rem;
        padding-bottom: 2rem;
        color: white;
        width: 31rem;
        border-radius: 0.9rem;
        box-shadow: 1px 1px 6px 1px rgb(0, 54, 201);
    }

    .star {
        margin: auto;
        display: block;
        width: 6rem;
        height: 6rem;
    }

    .head {
        font-size: 2.3rem;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-align: center;
    }

    .txt {
        font-size: 1.8rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }

}