@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');


.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 3rem;
  margin-top: 4rem;
}

.left {
  width: 60%;
  padding-left: 3rem;

}

.left h1 {
  text-align: left;
  font-family: 'poppins', sans-serif;
  padding-bottom: 1rem;
  background-color: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  font-weight: 600;
  margin-bottom: -1rem;
  text-align: center;
}

.line {
  margin-bottom: 3rem;
}

.left p {
  text-align: left;
  font-size: .8rem;
  padding-bottom: 1rem;

}

.tble {
  border: 1px dashed rgb(20, 179, 47);
  padding: 2rem;

}

.tble table {
  width: 100%;
}

.left p i {
  margin-right: .8rem;

}

.tble h3 {
  text-align: left;
  font-family: 'Fjalla One', sans-serif;
  background: -webkit-linear-gradient(#116a2dcf, #103a52c4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-top: 2rem;
}

.tble p i {
  color: rgb(26, 153, 47);
}

.tble table tr th {
  font-weight: 500;
  font-size: 1rem;
}

.tble table tr td {
  font-size: .9rem;
}

table {
  font-family: 'poppins', sans-serif;
  border-collapse: collapse;

}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.right {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;


  font-family: 'poppins', sans-serif;

}

.wrapper {
  width: 33rem;
  background: #FFFFFF;
  padding: 20px 15px;
  border-radius: 10px;
  margin-top: 0rem;

}

.wrapper h2 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.3rem;
  font-weight: 800;
  text-align: left;
  background: -webkit-linear-gradient(#116a2dcf, #114969);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.wrapper .divBar {
  display: flex;
  width: 120px;
  background: rgb(26, 153, 47);
  ;
  height: 6px;
  border-radius: 10px;
  margin: 10px 0px;

}

.wrapper .calculator {
  position: relative;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 0px;
}

.calculator .calculatorInput {
  position: relative;
  width: 50%;
  justify-content: space-around;
  padding: 5px 10px;
  display: block;
}

.calculator .calculatorInput input {
  width: 100%;
  height: 35px;
  padding: 20px 14px;
  font-family: 'poppins', sans-serif;
  font-weight: 400;
  font-size: .8rem;
  border: 1px solid #7d7d7d;
  border-radius: 4px;
}

.calculator .calculatorInput input:focus {
  outline: none;
  border: 1px solid #961251;
}

.calculator .calculatorInput label {
  color: rgb(26, 153, 47);
  font-size: .8rem;
  padding: 2px 4px;
  font-weight: 500;

}

.calculator .calculatorInput button {
  width: 100%;

  padding: .6rem;
  margin-top: 20px;
  font-weight: 700;
  cursor: pointer;
  font-family: 'poppins', sans-serif;
  background: linear-gradient(#116a2d, #0f5278a6);
  border: 0;
  color: #FFFFFF;
  font-size: .9rem;
  text-transform: uppercase;
  border-radius: 4px;

}

/*chrome, safari,edge,opera*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/firefox/ input[type=number] {
  -moz-appearance: textfield;
}

.wrapper .calculatorResult {
  position: relative;
  padding: 10px;
  margin: 20px 0;
  display: block;
}

.wrapper .calculatorResult ul {
  margin-top: -1rem;
  width: 100%;
  background: rgb(236, 254, 239);
  ;
  padding: 10px 15px;
  border-radius: 5px;
}

.wrapper .calculatorResult li {
  list-style: none;
  line-height: 28px;
  font-weight: 500;
  font-size: .9rem;
  text-align: left;
}

.calculatorResult .loanEmi {
  font-weight: 700;
  font-size: 1rem;
  color: rgb(26, 153, 47);
}

.calculatorResult .loanPrinciple,
.calculatorResult .loanInterestRate,
.calculatorResult .loanTotal {
  color: #333332;
  font-weight: 700;

}

.main {
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -1rem;

}

.logo3 img {
  width: 10rem;
  margin-left: -3rem;

}

.star1 img {
  width: 6rem;
  margin-left: -3rem;
}

.l1 .star2 {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 2rem;
}

.r1 img {
  width: 10rem;
}

/* //////////////////////////////////////////////////////*/

.headtitle {
  font-size: 2rem;
  font-weight: 600;

}

.rightIn1 {
  display: flex;
  flex-direction: row;

  gap: 2rem;
}

.loanbtn {
  margin-top: 3.5rem;
}

.resulthold {
  margin-top: 3rem;

  background-color: #f3fbff;
  padding: 2rem;
}

.in {
  height: 2rem;
  width: 16rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.loanbtn {
  height: 2.3rem;
  width: 16rem;
  border-radius: 0.4rem;
  border: none;
  font-size: 1.1rem;
  background-color: rgb(129, 194, 244);
}

.resultIn {
  margin-left: 0.5rem;
  border: none;
  background-color: transparent;
  color: rgb(213, 0, 0);
  font-size: 1.1rem;

}

@media screen and (max-width:768px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0rem;
    overflow: hidden;
  }

  .left {
    width: 100%;
    padding: 1rem;

  }

  .left h1 {
    text-align: center;
    font-family: 'Fjalla One', sans-serif;
    padding-bottom: 1rem;
    background: -webkit-linear-gradient(#116a2dcf, #2d8cc3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    width: 100%;
    font-size: 1.5rem;
  }

  .left p {
    font-size: .8rem;
  }

  .tble {
    width: 100%;
  }

  .tble table {
    width: 100%;
  }

  .tble table tr th {
    font-weight: 500;
    font-size: .7rem;
  }

  .tble table tr td {
    font-size: .7rem;
  }

  .wrapper {
    width: 100%;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 10px;
  }

  .wrapper h2 {

    font-family: 'Fjalla One', sans-serif;
    font-size: 1.3rem;
    font-weight: 800;
    text-align: left;
    background: -webkit-linear-gradient(#116a2dcf, #114969);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .l1 .star2 {
    font-size: .9rem;
    font-weight: 300;
    margin-left: 0rem;
  }

  .star1 img {
    width: 6rem;
    margin-left: -3rem;
  }

  .logo3 img {
    width: 10rem;
    margin-left: -2rem;

  }

  .right1 {
    margin-left: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .in {
    height: 5rem;
    width: 29rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
  }

  .resultIn {
    margin-left: 0.5rem;
    border: none;
    background-color: transparent;
    color: rgb(213, 0, 0);
    font-size: 2rem;
    margin-bottom: 2rem;

  }

  .loanbtn {
    height: 7rem;
    width: 27rem;
    border-radius: 0.5rem;

    font-size: 2rem;
    background-color: rgb(129, 194, 244);
  }

  .lab {
    font-size: 2rem;

  }


}